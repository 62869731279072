

































import { Component } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { Heading, HeadingLevel, Picker } from '../../../../contracts'

import { FormFieldset } from '../../../atoms'
import { HeadingForm, Iterator, LinkForm, SetForm } from '../../../molecules'

import { AbstractModuleForm } from '../../_abstract'

import { questionContentFactory } from '../Question.factory'
import { QuestionModule, QuestionModuleContent } from '../Question.contracts'

/**
 * TODO: Document all methods and properties.
 *
 * @author Łukasz Bęben <lukasz.beben@movecloser.pl>
 */
@Component({
  name: 'QuestionModuleForm',
  components: { FormFieldset, LinkForm, Iterator, SetForm, HeadingForm }
})
export class QuestionModuleForm extends AbstractModuleForm<QuestionModule> {
  //
  protected initialContent: QuestionModuleContent = questionContentFactory()

  public icons = DashmixIconName
  public picker = Picker

  /**
   * Checks whether heading is present
   */
  public get hasHeader (): boolean {
    return typeof this._content.heading !== 'undefined' && !!this._content.heading
  }

  public set hasHeader (value: boolean) {
    if (!value) {
      this._content.heading = undefined
      return
    }

    this._content.heading = {
      level: HeadingLevel.H5,
      text: ''
    }
  }

  /**
   * Fires when heading updates.
   * @param heading
   */
  public onHeadingUpdate (heading: Heading): void {
    this._content = {
      ...this._content,
      heading: heading
    }
  }
}
export default QuestionModuleForm
